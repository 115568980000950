
.slider-nav > div {
  cursor:pointer;
  width: 100%;
}
.slider-nav figure{
  position: relative;
  width: 100%;
  height:75px;
}
.slider-nav figure img{
  width: auto;
  height: 100%;
  object-fit: contain;
}

.slider-nav > div :hover{
  opacity: 0.9;
}
.active-slide {
  border: solid #C0CF8A 3px;
  opacity: 0.5;
}

.slider-for > div{
  cursor: pointer;
  width: 100%;
  aspect-ratio: 10/8;
  margin-bottom: 20px;
  
}
.slider-for figure{
  position: relative;
  width: 99%;
  height: 100%;
  aspect-ratio: 10/8;
}
.slider-for > div img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slider-for > div:hover{
  opacity: 0.6;

}


.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev {
  left: -25px;
  z-index: 2;
}

.slick-next {
  right: -20px;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: #292AFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
  content: '←';
}

.slick-next:before {
  content: '→';
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}


.leaflet-popup-tip {
  background: rgb(211, 242, 254);
}

.leaflet-popup-content {
  background: rgb(211, 242, 254);
}

.leaflet-tooltip {
  background: rgb(211, 242, 254);
  border: none;
}

/* .leaflet-tooltip-[directionの値] */
.leaflet-tooltip-top:before {
  border-top-color: rgb(211, 242, 254);
}

.slider-nav.hidden{
  display: none !important;
}
